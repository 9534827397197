import React, { Component } from 'react';

import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

import { NumberWithSeparators } from '../Util/NumberFormatting';
import API, { UploadFiles } from '../Util/api';
import axios from 'axios';
import {
 DocumentsNodeType,
 DocumentFoldersNodeType,
} from '../Util/Nodes';
import {
  GetDocumentUploadsPathForApi,
  GetUserOrganizationProjectDocumentFolderUploadsPathForApi,
  GetUserOrganizationProjectTaskUploadsPathForApi,
  GetUserOrganizationProjectApprovalTaskUploadsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemUploadsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemTaskUploadsPathForApi,
} from '../Util/api';
import {
  HandlePreCaptureFieldGathering,
  GetPreCaptureFieldGatheringContent,
} from '../Util/PreCaptureFieldGathering';

const styles = theme => ({
  dropZoneContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fileDropZone: {
    height: "100%",
  },
  gridContainer: {
    position:"relative",
    margin:"auto",
    width:300,
  },
  gridItem: {
    textAlign:"center",
  },
});

const UploadProgressItem = {
  FileName: "",
  Content: null,
};

export const HandleUploadProgress = (props, getCancelToken, getState, setState, handleAlert) =>
  (fileName, totalSize, completedSize, completedAtServer, completionResponse, completionError) => {
  
  let percentComplete = 100 * ((1 + completedSize) / totalSize);

  let items = [...getState("UploadProgressItems")];
  let itemIndex = -1;
  for (let i = 0; i < items.length; i++) {
    if (items[i].FileName === fileName) {
      itemIndex = i;
      break;
    }
  }
  if (itemIndex === -1) {
    let newItem = {...UploadProgressItem};
    newItem.FileName = fileName;
    items = items.concat(newItem);
    itemIndex = items.length - 1;
  }
  items[itemIndex].CompletedAtServer = completedAtServer;
  items[itemIndex].CompletionResponse = completionResponse;
  items[itemIndex].CompletionError = completionError;
  items[itemIndex].Content = (
    <div key={fileName} style={{marginBottom:16,overflowX:"hidden"}}>
      <DialogContentText>{fileName}</DialogContentText>
      <LinearProgress 
        color="primary"
        style={{
          backgroundColor: "#ddd",
        }}
        variant="determinate"
        value={percentComplete}
      />
    </div>
  );

  setState({UploadProgressItems: items});

  let bodyContent = [];
  let allComplete = true;
  for (let i = 0; i < items.length; i++) {
    bodyContent.push(items[i].Content);
    if (!items[i].CompletedAtServer) {
      allComplete = false
    }
  }

  handleAlert({ 
    Closed: allComplete,
    Title: "Upload Progress",
    BodyContent: bodyContent,
    FullWidth: true,
    CloseLabel: "CANCEL",
    CloseCallback: () => getCancelToken().cancel(),
    DisableBlurClose: true,
  });

  if (items.length === getState("FileCount") && allComplete) {
    if (props.skipCompleteAlert) {
      if (props.onClose) {
        props.onClose(items);
      }
    } else {
      const completionMessageContent = 
        (props.completionMessage)
          ? (
            <div>
              {props.completionMessage}
            </div>
            )
          : (!props.reservationUri)
            ? (
              <div>
                Uploads are being processed and will be available momentarily.
              </div>
              )
            : null;
            
      handleAlert({ 
        Title: "Upload complete",
        BodyContent: completionMessageContent,
        DialogWidth: "xs",
        CloseCallback: () => { if (props.onClose) props.onClose(items); },
      });
    }
  }
}

class CaptureCore extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      UploadProgressItems: [],
      FileCount: 0,
      CaptureOriginal: false,
      DocumentFolderID: "",
      TargetIsNodeAndFinalDestination: false,
      IsWorkspace: props.isWorkspace,
    }

    this.CancelToken = null;
    this.Files = [];

    this.OpenFunc = null;
  }

  analyzeNode = () => {
    if (this.props.node) {
      // const getFieldIdAndValues = apiParams => {
      //   let allow = false;
      //   let fieldId = "";
      //   let fieldValues = [];
      //   Object.keys(apiParams).forEach(k => {
      //     if (k.startsWith("fieldValueType")) {
      //       allow = (apiParams[k] === "" || apiParams[k] === "FieldValueType_FullValue");
      //     } else if (k.startsWith("fieldID")) {
      //       fieldId = apiParams[k];
      //     } else if (k.startsWith("fieldValue")) {
      //       fieldValues = [apiParams[k]];
      //     }
      //   });
      //   if (allow && fieldId && fieldValues.length) {
      //     return {[fieldId]: fieldValues};
      //   }
      //   return null;
      // };
      let node = this.props.node;
      // let lastNodeAnalyzedIsValidDestination = false;
      if (node) {
        switch (node.NodeType) {
          // case "folderHierarchies":
          //   let FieldIDsAndValues = {};
          //   while (node) {
          //     if (node.ApiParams) {
          //       let fieldIdAndValues = getFieldIdAndValues(node.ApiParams);
          //       if (fieldIdAndValues) {
          //         FieldIDsAndValues = {...FieldIDsAndValues, ...fieldIdAndValues};
          //         // If this node is a full-value field node, it's a valid destination
          //         lastNodeAnalyzedIsValidDestination = true;
          //       }
          //     }
          //     node = node.ParentNode;
          //   }
          //   this.setState({
          //     FieldIDsAndValues,
          //     TargetIsNodeAndFinalDestination: lastNodeAnalyzedIsValidDestination,
          //   });
          //   break;
          case DocumentsNodeType:
          case DocumentFoldersNodeType:
            this.setState({
              DocumentFolderID: node.DocumentFolderID,
              IsWorkspace: node.IsWorkspace,
              TargetIsNodeAndFinalDestination: true,
            });
            break;
          default:
            break;
        }
      }
    }
  }

  handleFileDrop = async (files, fieldIDsAndValues, tags, assetItemTags) => {
    // Reset some items
    this.CancelToken = axios.CancelToken.source();
    this.setState({FileCount: files.length, UploadProgressItems: []});

    const {
      organizationId,
      projectId,
      approvalIdForApprovalAssignmentContext,
      taskIdForTaskAssignmentContext,
      assetItemForAssetItemContext,
    } = this.props;
    const {
      DocumentFolderID,
      IsWorkspace,
    } = this.state;

    let reservationUri;
    if (this.props.reservationUri) {
      reservationUri = this.props.reservationUri;
    } else if (IsWorkspace && DocumentFolderID) {
      reservationUri = GetUserOrganizationProjectDocumentFolderUploadsPathForApi(organizationId,
        projectId, DocumentFolderID);
    } else if (approvalIdForApprovalAssignmentContext) {
      if (assetItemForAssetItemContext) {
        if (taskIdForTaskAssignmentContext) {
          reservationUri = GetUserOrganizationProjectApprovalAssetItemTaskUploadsPathForApi(organizationId,
            projectId, approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext.AssetID,
            assetItemForAssetItemContext.ID, taskIdForTaskAssignmentContext);
        } else {
          reservationUri = GetUserOrganizationProjectApprovalAssetItemUploadsPathForApi(organizationId,
            projectId, approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext.AssetID,
            assetItemForAssetItemContext.ID);
        }
      } else if (taskIdForTaskAssignmentContext) {
        reservationUri = GetUserOrganizationProjectApprovalTaskUploadsPathForApi(organizationId,
          projectId, approvalIdForApprovalAssignmentContext, taskIdForTaskAssignmentContext);
      }
    } else if (taskIdForTaskAssignmentContext) {
      reservationUri = GetUserOrganizationProjectTaskUploadsPathForApi(organizationId,
        projectId, taskIdForTaskAssignmentContext);
    } else {
      reservationUri = GetDocumentUploadsPathForApi(organizationId, projectId);
    }

    const reservationParams = 
      (this.props.reservationParams)
        ? this.props.reservationParams
        : {};

    if (this.props.singleFile) {
      // singleFile
      if (files.length > 1) {
        files = [files[0]];
      }

      // singleFileMaxSize
      if (this.props.singleFileMaxSize && files[0].size > this.props.singleFileMaxSize) {
        return this.handleApiError(`File size must be ${NumberWithSeparators(this.props.singleFileMaxSize)} bytes or smaller.`);
      }
      
      // singleFileDisallowGIF
      if (this.props.singleFileDisallowGIF) {
        const foundGif = await new Promise((resolve) => {
          const isGif = result => {
            return /^GIF/.test(result);
          }
          const reader = new FileReader();
          reader.addEventListener('load', () => resolve(
            isGif(reader.result)
          ));
          reader.readAsText(files[0]);
        });
        if (foundGif) {
          return this.handleApiError("GIF uploads are not allowed.");
        }
      }
    }

    const processFiles = () => {
      let onCompletedFileHandler = async (file, reservation) => {
        reservation.ContentType = file.type;
        reservation.OriginalFilename = file.name;
        reservation.OriginalFilepath = file.path;
        reservation.OriginalFileSize = file.size;
        reservation.FieldIDsAndValues = fieldIDsAndValues;
        reservation.Tags = tags;
        reservation.AssetItemTags = assetItemTags;
        if (DocumentFolderID) {
          reservation.DocumentFolderID = DocumentFolderID;
        }
        if (this.props.reservationDocumentId) {
          reservation.DocumentID = this.props.reservationDocumentId;
        }
        if (this.props.isAppend) {
          reservation.IsAppend = true;
        }

        if (this.props.onComplete && !this.props.includeResponseInOnComplete) {
          this.props.onComplete(reservation, file);
        }

        if (this.props.skipFinalization) {
          return { resp: { data: reservation } };
        } else {
          return await API.put(reservationUri, [reservation],
            {
              params: {
                ...reservationParams,
                uniqueId: reservation.UniqueId,
                captureOriginal: this.state.CaptureOriginal,
              }
            })
            .then(resp => { 
              if (this.props.onComplete && this.props.includeResponseInOnComplete) {
                this.props.onComplete(reservation, file, resp);
              }
              return { resp };
            })
            .catch(err => { return { err }; });
        }
      }

      UploadFiles(files, reservationUri, reservationParams, this.CancelToken, 
        HandleUploadProgress(
          this.props,
          () => this.CancelToken,
          name => this.state[name],
          state => this.setState(state),
          this.handleAlert
        ),
        onCompletedFileHandler, this.handleAlert, this.handleApiError);
    };

    if (this.props.gatherFieldValuesBeforeUpload) {
      if (!fieldIDsAndValues) {
        this.handlePreCaptureFieldGathering(files);
      } else {
        processFiles();
      }
    } else {
      processFiles();
    }
  }

  handlePreCaptureFieldGathering = files => {
    const handlePreGatherFields = () => {
      this.Files = files;
    }

    const handleCapture = () => {
      this.handleFileDrop(files, {});
    }

    if (!this.props.organizationId || !this.props.projectId) {
      this.handleCapture();
    }
    
    HandlePreCaptureFieldGathering(
      this.props.organizationId,
      this.props.projectId,
      this.handleApiError,
      () => this.state,
      state => this.setState(state),
      files.length,
      handlePreGatherFields,
      handleCapture,
      (this.state.IsWorkspace && this.state.DocumentFolderID) ? this.state.DocumentFolderID : null,
      this.props.taskIdForTaskAssignmentContext,
      this.props.approvalIdForApprovalAssignmentContext,
      this.props.assetItemForAssetItemContext,
    );
  }

  handleBeginFileUpload = DocumentFolderID => {
    this.setState({DocumentFolderID});
    if (this.OpenFunc) {
      this.OpenFunc();
    }
  }

  getInnerContent = openFunc => {
    const {
      CaptureOriginal,
    } = this.state;
    const {
      classes,
      theme,
      children,
      onGetChildren,
      forceHideInstructionContent,
      hideCaptureOriginalOption,
      singleFile,
      noMargin,
      marginTop,
    } = this.props;

    if (!this.OpenFunc && openFunc) {
      this.OpenFunc = openFunc;
    }

    const captureOriginalGridItem = (!hideCaptureOriginalOption)
      ? (
        <Grid item xs={12} className={classes.gridItem} style={{marginTop:theme.spacing(1)}}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={CaptureOriginal}
              />
            }
            onClick={() => this.setState({CaptureOriginal:!CaptureOriginal})}
            label="Capture original format" />
        </Grid>
      ) : null;

    return (children)
      ? children
      : (onGetChildren)
        ? onGetChildren(openFunc)
        : (!forceHideInstructionContent)
          ? (
            <Grid container spacing={2} className={classes.gridContainer}
              style={{marginTop:(noMargin) ? 0 : (marginTop) ? marginTop : theme.spacing(16), }}>
              <Grid item xs={12} className={classes.gridItem}>
                <Typography variant="h5">
                  {`Drag and drop file${(singleFile) ? "" : "s"}`}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <Typography variant="body1">
                  Or
                  <Button variant="contained"
                    style={{marginTop:-4,marginLeft:8}}
                    onClick={openFunc}>
                    {`SELECT FILE${(singleFile) ? "" : "S"}`}
                  </Button>
                </Typography>
              </Grid>
              {captureOriginalGridItem}
            </Grid>
          ) : null;
  }

  handleApiError = err => {
    this.props.onApiError(err);
    if (err) {
      setTimeout(() => this.props.onApiError(null), 1);
    }
  }
  
  handleAlert = details => {
    this.props.onAlert(details);
  }

  componentDidMount() {
    if (this.props.onSetBeginFileUploadFunc) {
      this.props.onSetBeginFileUploadFunc(this.handleBeginFileUpload);
    }
    this.analyzeNode();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.node && !prevProps.node)
      || (prevProps.node && this.props.node && prevProps.node.UniqueId !== this.props.node.UniqueId)
    ) {
      this.analyzeNode();
    }
  }

  render() {
    const {
      classes,
      theme,
      children,
      fullWidth,
      noContent,
      acceptTypes,
      organizationId,
      projectId,
      taskIdForTaskAssignmentContext,
      approvalIdForApprovalAssignmentContext,
      assetItemForAssetItemContext,
    } = this.props;
    const {
      DocumentFolderID,
      IsWorkspace,
    } = this.state;

    const preCaptureFieldGatheringContent = GetPreCaptureFieldGatheringContent(
      organizationId,
      projectId,
      () => this.state,
      state => this.setState(state),
      this.handleApiError,
      this.handleAlert,
      (fieldIDsAndValues, tags, assetItemTags) => this.handleFileDrop(this.Files, fieldIDsAndValues, tags, assetItemTags),
      null,
      (IsWorkspace && DocumentFolderID) ? DocumentFolderID : null,
      taskIdForTaskAssignmentContext,
      approvalIdForApprovalAssignmentContext,
      assetItemForAssetItemContext,
    );

    return (
      <React.Fragment>
        {preCaptureFieldGatheringContent}
        <div style={{
          height:"100%",
          display: (noContent) ? "none" : undefined
        }}>
          <div className={classes.dropZoneContainer}>
            <Dropzone 
              onDrop={files => this.handleFileDrop(files)}
              noClick
              noKeyboard
              accept={acceptTypes}
            >
              {({getRootProps, getInputProps, open: openFunc}) => (
                  <div {...getRootProps()} className={classes.fileDropZone}
                    style={{
                      width: 
                        (children)
                          ? "100%"
                          : (fullWidth)
                            ? "100%"
                            // this calculation fixes an issue with the drop zone width being larger than 100% on smaller screens (mobile devices)
                            : `calc(100% - ${theme.spacing(2)}px)`,
                    }}>
                    <input {...getInputProps()} />
                    {this.getInnerContent(openFunc)}
                  </div>
              )}
            </Dropzone>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CaptureCore.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  taskIdForTaskAssignmentContext: PropTypes.string,
  approvalIdForApprovalAssignmentContext: PropTypes.string,
  isWorkspace: PropTypes.bool,
  assetItemForAssetItemContext: PropTypes.object,
  reservationUri: PropTypes.string,
  reservationParams: PropTypes.object,
  isAppend: PropTypes.bool,
  skipFinalization: PropTypes.bool,
  onComplete: PropTypes.func,
  completionMessage: PropTypes.string,
  includeResponseInOnComplete: PropTypes.bool,
  singleFile: PropTypes.bool,
  singleFileMaxSize: PropTypes.number,
  singleFileDisallowGIF: PropTypes.bool,
  acceptTypes: PropTypes.string,
  node: PropTypes.object,
  forceHideInstructionContent: PropTypes.bool,
  hideCaptureOriginalOption: PropTypes.bool,
  skipCompleteAlert: PropTypes.bool,
  noMargin: PropTypes.bool,
  marginTop: PropTypes.number,
  fullWidth: PropTypes.bool,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onSetBeginFileUploadFunc: PropTypes.func,
  children: PropTypes.object,
  onGetChildren: PropTypes.func,
  gatherFieldValuesBeforeUpload: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(CaptureCore);